<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />

		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div
									class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.kod }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0"
										v-if="$router.options.history.state.back != null" icon="pi pi-times"
										@click="$router.go(-1)" />
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="SERVİS RAPORU" entityName="serviceappointment">
					</EntityHeader>
					<div class="grid">
						<div class="col-5">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.firmaName" ref="entity_firma" label="Firma"
									entityName="account" nameField="name" :state="true" :required="true" :disabled="true"
									@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.firmaName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.firmaName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="servisTalebi" v-model="mainData.servisTalebiName" ref="entity_servisTalebi" label="Servis Talebi"
									entityName="incident" nameField="title" secondNameField="ticketnumber" :state="true" :required="true" :disabled="true"
									parentFieldName="customerid" :parentId="firmaSelected"
									@itemSelected="servisTalebiSelected = $event" @itemCleared="servisTalebiSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.servisTalebiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.servisTalebiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<EntityLookup id="tezgah" v-model="mainData.tezgahName" ref="entity_tezgah"
									label="Tezgah" entityName="bm_unite" nameField="bm_serino" secondNameField="bm_urunid" secondNameFieldType="lookup" :state="true" :required="true"
									parentFieldName="bm_kurulummusteriid" :parentId="firmaSelected"
									@itemSelected="tezgahSelected = $event" @itemCleared="tezgahSelected = null" disabled>
								</EntityLookup>
								<span v-if="v$.mainData.tezgahName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgahName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<EntityLookup id="urun" v-model="mainData.urunName" ref="entity_urun"
									label="Ürün" entityName="bm_servispersonel" nameField="bm_name" :state="true" :required="true"
									@itemSelected="urunSelected = $event" @itemCleared="urunSelected = null" disabled
									secondNameField="productnumber">
								</EntityLookup>
								<span v-if="v$.mainData.urunName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.urunName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>İŞ EMRİ BÖLÜMÜ</h5>
					<div class="grid">
						<div class="col-9">
							<div class="field p-fluid">
								<label for="garantiDahilmi">Kaynaklar</label>
								<MultiSelect v-model="selectedResources" :options="resourcesData" optionLabel="name" placeholder="Kaynak seçiniz" :filter="true" class="multiselect-custom" >
									<template #value="slotProps">
										<div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.code">
											<img src="layout/images/entityImages/resource.gif" class="mr-2" width="18" />
											<div>{{option.name}}</div>
										</div>
										<template v-if="!slotProps.value || slotProps.value.length === 0">
											Kaynak seçiniz
										</template>
									</template>
									<template #option="slotProps">
										<div class="country-item">
											<img src="layout/images/entityImages/resource.gif" class="mr-2" width="18" />
											<div>{{slotProps.option.name}}</div>
											<div class="muted-text ml-7">{{slotProps.option.konum}}</div>
										</div>
									</template>
								</MultiSelect>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="turu">Türü <span style="color:red">*</span></label>
								<Dropdown id="turu" v-model="mainData.turu" :options="SM_turu" optionLabel="Value" optionValue="AttributeValue" placeholder="Türü" :showClear="true"/>
								<span v-if="v$.mainData.turu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.turu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>SERVİS FORMU</h5>
					<div class="grid">
						<div class="col-4">
							<div class="field p-fluid">
								<label for="makinaCalismaSaati">Makina Çalışma Saati</label>
								<InputNumber id="makinaCalismaSaati" v-model="mainData.makinaCalismaSaati" mode="decimal" locale="tr-TR" :minFractionDigits="0" :min="0"/>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="servisUcretlimi">Servis Ücretli mi?</label>
								<Dropdown id="servisUcretlimi" v-model="mainData.servisUcretlimi" :options="SM_servisUcretlimi" optionLabel="Value" optionValue="AttributeValue" placeholder="Servis Ücretli mi?" :showClear="true" />
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="sonuc">Sonuç <span style="color:red">*</span></label>
								<Dropdown id="sonuc" v-model="mainData.sonuc" :options="SM_sonuc" optionLabel="Value" optionValue="AttributeValue" placeholder="Sonuç" :showClear="true" />
								<span v-if="v$.mainData.sonuc.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.sonuc.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-8">
							<div class="field p-fluid">
								<label for="yapilanlar">Yapılanlar <span style="color:red">*</span></label>
								<Textarea id="yapilanlar" rows="6" :autoResize="false" v-model="mainData.yapilanlar" />
								<span v-if="v$.mainData.yapilanlar.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.yapilanlar.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-4">
							<div class="field p-fluid">
								<label for="ilave">İlave</label>
								<Textarea id="ilave" rows="6" :autoResize="false" v-model="mainData.ilave" />
							</div>
						</div>
							
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>MOBİL UYGULAMADAN GELENLER</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="imzalayanFirmaPersoneli">İmzalayan Firma Personeli</label>
								<InputText id="imzalayanFirmaPersoneli" type="text" v-model="mainData.imzalayanFirmaPersoneli" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="servisRaporunuHazirlayanTeknikPersonelName">Hazırlayan Teknik Personel</label>
								<InputText id="servisRaporunuHazirlayanTeknikPersonelName" type="text" v-model="mainData.servisRaporunuHazirlayanTeknikPersonelName" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="servisRaporunuimzalayanTeknikPersonelName">İmzalayan Teknik Personel</label>
								<InputText id="servisRaporunuimzalayanTeknikPersonelName" type="text" v-model="mainData.servisRaporunuimzalayanTeknikPersonelName" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="taslak">Taslak</label>
								<Dropdown id="taslak" v-model="mainData.taslak" :options="SM_taslak" optionLabel="Value" optionValue="AttributeValue" placeholder="Taslak" :showClear="true" />
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<label for="gonderilenMailAdresleri">Gönderilen Mail Adresleri</label>
								<InputText id="gonderilenMailAdresleri" type="text" v-model="mainData.gonderilenMailAdresleri" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="tezgahDurumu">Tezgah Durumu <span style="color:red">*</span></label>
								<Dropdown id="tezgahDurumu" v-model="mainData.tezgahDurumu" :options="SM_tezgahDurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Tezgah Durumu" :showClear="true" />
								<span v-if="v$.mainData.tezgahDurumu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgahDurumu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="makinaGuvenlikKilidi">Makina Güvenlik Kilidi <span style="color:red">*</span></label>
								<Dropdown id="makinaGuvenlikKilidi" v-model="mainData.makinaGuvenlikKilidi" :options="SM_makinaGuvenlikKilidi" optionLabel="Value" optionValue="AttributeValue" placeholder="Makina Güvenlik Kilidi" :showClear="true" />
								<span v-if="v$.mainData.makinaGuvenlikKilidi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.makinaGuvenlikKilidi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="torna1">Torna 1</label>
								<InputText id="torna1" type="text" v-model="mainData.torna1" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="torna2">Torna 2</label>
								<InputText id="torna2" type="text" v-model="mainData.torna2" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="torna3">Torna 3</label>
								<InputText id="torna3" type="text" v-model="mainData.torna3" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="torna4">Torna 4</label>
								<InputText id="torna4" type="text" v-model="mainData.torna4" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="islemeMerkezi1">İşleme Merkezi 1</label>
								<InputText id="islemeMerkezi1" type="text" v-model="mainData.islemeMerkezi1" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="islemeMerkezi2">İşleme Merkezi 2</label>
								<InputText id="islemeMerkezi2" type="text" v-model="mainData.islemeMerkezi2" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="islemeMerkezi3">İşleme Merkezi 3</label>
								<InputText id="islemeMerkezi3" type="text" v-model="mainData.islemeMerkezi3" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="islemeMerkezi4">İşleme Merkezi 4</label>
								<InputText id="islemeMerkezi4" type="text" v-model="mainData.islemeMerkezi4" disabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 4214,
			mainData: {},

			resourcesData: [],
			selectedResources: null,

			nestedMenuitems: [
				{
					label: 'Kaydet',
					icon: 'pi pi-fw pi-save',
					command: () => { this.OnSave(); },
                },
			]
		}
	},
	async created() {
		this.crmService = new CrmService();

		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		let loader = this.$loading.show({
			container: this.$refs.smsContainer
		});

		debugger;
		try {
			if (this.$route.params.incidentid) {
				let data = await this.crmService.getServisTalebiById(this.$route.params.incidentid);
				if (data.jsonData.length > 0) {
					let jsonDataIncident = data.jsonData[0];
					this.$refs.entity_servisTalebi.setDefaultData({ "Value": jsonDataIncident.entityId, "Name": jsonDataIncident.baslik });
					this.servisTalebiSelected = { "Value": jsonDataIncident.entityId, "Name": jsonDataIncident.baslik };

					this.$refs.entity_firma.setDefaultData({ "Value": jsonDataIncident.firmaId, "Name": jsonDataIncident.firmaName });
					this.firmaSelected = { "Value": jsonDataIncident.firmaId, "Name": jsonDataIncident.firmaName };
					debugger;
				}
			}

			this.resourcesData = await this.crmService.GetResources();
			this.resourcesData = this.resourcesData.jsonData;
		} catch (error) {
			console.log(error);
		}
		finally {
			loader.hide();
		}
	},
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		profileData() {
			return this.$store.getters.getProfile;
		},
		SM_turu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_turu');
		},
		SM_servisUcretlimi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_servisucretlimi');
		},
		SM_sonuc: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_durum');
		},
		SM_taslak: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_taslak', 'bool');
		},
		SM_tezgahDurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_tezgahdurumu');
		},
		SM_makinaGuvenlikKilidi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_makinaguvenlikkilidi');
		},
		firmaSelected: {
			get: function () {
				if (this.mainData["firmaId"]) {
					return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["firmaId"] = null;
					this.mainData["firmaName"] = null;
				}
				else {
					this.mainData["firmaId"] = newValue.Value;
					this.mainData["firmaName"] = newValue.Name;
				}
			}
		},
		servisTalebiSelected: {
			get: function () {
				if (this.mainData["servisTalebiId"]) {
					return { "Value": this.mainData["servisTalebiId"], "Name": this.mainData["servisTalebiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["servisTalebiId"] = null;
					this.mainData["servisTalebiName"] = null;
				}
				else {
					this.mainData["servisTalebiId"] = newValue.Value;
					this.mainData["servisTalebiName"] = newValue.Name;

					this.crmService.GetEntityById({
						"entityName": "incident",
						"entityId": newValue.Value,
						"columnNames": ["bm_uniteid", "productid"]})
					.then(data => {
						if (data.jsonData != null) {
							debugger;

							this.$refs.entity_tezgah.setDefaultData({ "Value": data.jsonData["bm_uniteid"], "Name": data.jsonData["bm_uniteidName"] });
							this.tezgahSelected = { "Value": data.jsonData["bm_uniteid"], "Name": data.jsonData["bm_uniteidName"] };

							this.$refs.entity_urun.setDefaultData({ "Value": data.jsonData["productid"], "Name": data.jsonData["productidName"] });
							this.urunSelected = { "Value": data.jsonData["productid"], "Name": data.jsonData["productidName"] };
						}
					})
					.catch(error => console.log(error));
				}
			}
		},
		tezgahSelected: {
			get: function () {
				if (this.mainData["tezgahId"]) {
					return { "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["tezgahId"] = null;
					this.mainData["tezgahName"] = null;
				}
				else {
					this.mainData["tezgahId"] = newValue.Value;
					this.mainData["tezgahName"] = newValue.Name;
				}
			}
		},
		urunSelected: {
			get: function () {
				if (this.mainData["urunId"]) {
					return { "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["urunId"] = null;
					this.mainData["urunName"] = null;
				}
				else {
					this.mainData["urunId"] = newValue.Value;
					this.mainData["urunName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnSave() {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					if (this.selectedResources.length > 0) {
						this.mainData["kaynaklar"] = this.selectedResources;
					}
					
					const response = await this.crmService.getServisRaporuCreate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							setTimeout(() => {
								this.$router.push({ name: 'serviceappointment', params: { id: response.olusturulanId } });
							}, 2000);
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		formatNumber(value) {
			debugger;
			if (value != undefined) {
				return value.toLocaleString('tr-TR');
			}
		},
		getCurrency() {
			let paraBirimi = '';
			switch (this.mainData.paraBirimiName) {
				case "Dolar":
					paraBirimi = " $"
					break;
				case "Euro":
					paraBirimi = " €"
					break;
				case "İsviçre Frankı":
					paraBirimi = " SFr."
					break;
				case "Türk Lirası":
					paraBirimi = " ₺"
					break;
				case "Yen":
					paraBirimi = " ¥"
					break;

				default:
					break;
			}
			return paraBirimi;
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},

	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Servis Raporları');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				firmaName: {
					required: helpers.withMessage('Firma alanı dolu olmalıdır!', required),
				},
				servisTalebiName: {
					required: helpers.withMessage('Servis Talebi alanı dolu olmalıdır!', required),
				},
				tezgahName: {
					required: helpers.withMessage('Tezgah alanı dolu olmalıdır!', required),
				},
				urunName: {
					required: helpers.withMessage('Ürün alanı dolu olmalıdır!', required),
				},
				turu: {
					required: helpers.withMessage('Türü alanı dolu olmalıdır!', required),
				},
				sonuc: {
					required: helpers.withMessage('Sonuç alanı dolu olmalıdır!', required),
				},
				yapilanlar: {
					required: helpers.withMessage('Yapılanlar alanı dolu olmalıdır!', required),
				},
				tezgahDurumu: {
					required: helpers.withMessage('Tezgah Durumu alanı dolu olmalıdır!', required),
				},
				makinaGuvenlikKilidi: {
					required: helpers.withMessage('Makina Güvenlik Kilidi alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
	.pi {
		font-family: primeicons;
		speak: none;
		font-style: normal;
		font-weight: 400;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		display: inline-block;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.true-icon {
		color: #256029;
	}

	.false-icon {
		color: #c63737;
	}

	.country-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	::v-deep(.multiselect-custom) {
		.p-multiselect-label:not(.p-placeholder) {
			padding-top: .50rem;
			padding-bottom: .50rem;
		}

		.country-item-value {
			padding: .25rem .5rem;
			border-radius: 3px;
			display: inline-flex;
			margin-right: .5rem;
			background-color: var(--primary-color);
			color: var(--primary-color-text);

			img.flag {
				width: 17px;
			}
		}
	}

	@media screen and (max-width: 640px) {
		.p-multiselect {
			width: 100%;
		}
	}
</style>
